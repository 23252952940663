import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes = [
  {
    path: '/',
    name: 'Главная',
    component: HomeView,
  },
  {
    path: '/players',
    name: 'Игроки',
    component: () => import(/* webpackChunkName: "about" */ '../views/PlayersView')
  },
  {
    path: '/login',
    name: 'Авторизация',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView')
  }
]
import {checkAuth} from '../../api';
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "font-bold" // active class for *exact* links.
})

router.beforeEach(async (to) => {
  if (to.path != '/login'){
    const result = await checkAuth({pass : localStorage.getItem('pass')});
    if (result.status == 'error'){
      router.push('login')
    }
  }
})

export default router
