<template>
    <div class="px-5 w-1/2 min-w-[300px]">
        <div class="border border-gray-200 bg-white rounded-lg overflow-hidden shadow-lg">
            <div>
                <img class="w-full h-[300px] object-fit-cover" :src="require(`../assets/avatars/${player.avatar}`)" alt="">
            </div>
            <div class="p-4">
                <div class="text-lg">
                    {{ player.firstName }} {{ player.lastName }}
                </div>
                <div class="text-sm">
                    {{ player.ages }} лет
                </div>
                <div v-if="type === 'main'">
                    <div class="mt-4 pt-3 border-t border-gray-100 font-bold">Результаты игр:</div>
                    <div class="mt-4">
                        <div v-for="game in games" :key="game.id">
                            {{game.name}} : {{player.gamesTotalWinResults && player.gamesTotalWinResults[game.id] ? player.gamesTotalWinResults[game.id] : 0}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props : ['player','type','games'],
    }
</script>