<template>
    <div class="px-4">
        <div class="bg-[#fff3cd] border border-[#ffeeba] text-[#856404] container mt-10 rounded-2xl sm:rounded-3xl  max-w-[800px] shadow shadow-xl shadow-gray-200 p-6 sm:p-10 mx-auto overflow-hidden">
            Данное веб-приложение было разработано исключительно в рамках обучайшей программы по VUE и будет дорабатываться. Ну и чтобы не проебывать проигрыши и победы, получается.
        </div>
    </div>

    <div class="px-4 sm:px-0">
        <div class="container mt-10 rounded-2xl sm:rounded-3xl bg-white max-w-[800px] shadow shadow-xl shadow-gray-200 p-6 sm:p-10 mx-auto overflow-hidden">
            <div class="flex-wrap flex pb-6 sm:pb-0 mx-[-1.5rem] sm:mx-[-1.25rem] overflow-auto flex-nowrap">
                <PlayerComponent v-for="player in players" :key="player.id" :games="games" :player="player"
                                 type="main"></PlayerComponent>
            </div>
            <div class="mt-10 flex justify-center">
                <a v-if="!formShow" class="inline-flex transition-opacity duration-300 hover:opacity-80 h-[50px] rounded-xl px-6 items-center bg-[#ffdd2d] " href="#"
                   @click.prevent="addGameResultButtonHandle">
                    Добавить результат
                </a>
            </div>

            <form v-if="formShow" action="" class="mt-4 sm:mt-10 sm:border sm:p-4 relative transition">
                <a class="absolute top-0 sm:top-[10px] right-[10px] z-10 w-[20px] flex" href="#"
                   @click.prevent="formShow = false;"><img alt="" class="flex"
                                                           src="../assets/close.svg"></a>
                <label class="block mb-4">
                    <span>Дата трахания</span>
                    <span class="block mt-2">
                    <input v-model="formData.gameDate" class=" rounded-md appearance-none w-full border py-2 px-4 bg-white" type="date">
                </span>
                </label>
                <label v-for="(game,key) in games" :key="key" :class="{'mt-4' : key > 0}" class="block">
                    <span>{{ game.name }}</span>
                    <span class="block mt-2">
                  <select v-model="formData.games[game.id]" class="rounded-md  appearance-none w-full border py-2 px-4 bg-white">
                      <option disabled value="">Выберите...</option>
                      <option v-for="player in players" :key="player.id" :value="player.id">
                          {{ player.firstName }}
                      </option>
                  </select>
              </span>
                </label>
                <div v-if="formError" class="mt-4 text-sm text-red-500">
                    {{ formError }}
                </div>
                <div class="mt-6 flex justify-center">
                    <button class="inline-flex transition-opacity duration-300 hover:opacity-80 h-[50px] rounded-xl px-6 items-center bg-[#ffdd2d] " type="submit"
                            @click.prevent="addGameResult">
                        Сохранить
                    </button>
                </div>
            </form>


        </div>
    </div>

    <vue-basic-alert
            ref="alert"
            :closeIn="3000"
            :duration="300"/>
</template>

<script>
    import {getGames, getUsers, updateGameResult} from '../../api';
    import PlayerComponent from '@/components/PlayerComponent';
    import VueBasicAlert from 'vue-basic-alert';

    export default {
        name: 'HomeView',
        async mounted() {
            this.updateUsers();
            const games = await getGames();
            if (games) {
                this.games = games;
            }
        },
        emits: ['loaderHandler'],
        data() {
            return {
                formData: {
                    games: {},
                    gameDate: null
                },
                formError: null,
                players: [],
                formShow: false,
                games: []
            }
        },
        methods: {
            async updateUsers(cb) {
                this.$emit('loadingHandler', true);
                const users = await getUsers();
                if (cb) {
                    cb();
                }
                if (users) {
                    this.players = users;
                    setTimeout(() => this.$emit('loadingHandler', false), 500)
                }
            },
            addGameResultButtonHandle() {
                this.formShow = true;
            },
            async addGameResult() {
                const result = await updateGameResult(this.formData)
                if (result) {
                    if (result.status === 'success') {
                        this.updateUsers(() => this.showAlert('success', 'Успех!', result.message));
                    } else {
                        this.formError = result.message;
                    }
                } else {
                    console.log(result);
                }
            },
            showAlert(type, header, message) {
                this.$refs.alert.showAlert(
                    type,
                    message,
                    header,
                    {
                        iconSize: 30,
                        iconType: 'solid',
                        position: 'top right'
                    }
                );
            }
        },
        watch: {
            players() {
                this.formError = null,
                    this.games.forEach((value) => {
                        this.formData.games[value.id] = '';
                    })
                this.formData.gameDate = new Date().toISOString().slice(0, 10);
            }
        },
        components: {
            PlayerComponent,
            VueBasicAlert
        }
    }
</script>

