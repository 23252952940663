<template >
  <div class="mainLoader fixed w-full h-full z-10 bg-white top-0 left-0 flex items-center justify-center" :class="{'hidden' : !isLoading}">
      <img class="max-w-30 shadow-[0_0_20px_rgba(0,0,0,0.4)] animate-spin-slow overflow-hidden rounded-[50%]" src="./assets/palec.jpg" alt="">
  </div>
  <div class="bg-white py-4 shadow-[0_0_20px_rgba(0,0,0,0.1)]	">
      <ul class="flex flex-wrap justify-center ">
          <li class="px-5">
              <router-link to="/">Главная</router-link>
          </li>
          <li class="px-5">
              <router-link to="/players">Игроки</router-link>
          </li>
      </ul>
  </div>
    <router-view @loading-handler="loadingHandler"></router-view>

    <footer class="mt-4 absolute left-0 w-full bottom-0 text-center">
        <div class="py-4 bg-white">А нам и нахуй не нужны, права ваши. (с)</div>
    </footer>
</template>

<script>
    export default {
        created() {

        },
        data(){
            return {
                isLoading : true,
            }
        },
        methods : {
            loadingHandler(isLoading){
                this.isLoading = isLoading;
            }
        },
        watch: {
            $route(to){
                document.title = to.name;
            }
        }
    }
</script>
