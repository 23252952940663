const apiUrl = process.env.VUE_APP_API_HOST;
export const checkAuth = async (data) => {
    return fetch(`${apiUrl}/site/check-auth`, {
        method: 'POST',
        headers: {},
        body:JSON.stringify(data),
    })
        .then(response => response.json())
        .catch((error) => {
                console.log(error)
            }
        )
}

export const login = async (data) => {
    return fetch(`${apiUrl}/site/login`, {
        method: 'POST',
        headers: {},
        body:JSON.stringify(data),
    })
        .then(response => response.json())
        .catch((error) => {
                console.log(error)
            }
        )
}
export const getUsers = async () => {
    return fetch(`${apiUrl}/site/test`, {
        method: 'POST',
        headers: {}
    })
        .then(response => response.json())
        /*.then(
            (data) => {
                this.players = data;
            }
        )*/
        .catch((error) => {
                console.log(error)
            }
        )
}

export const getGames = async () => {
    return fetch(`${apiUrl}/site/get-games`, {
        method: 'POST',
        headers: {}
    })
        .then(response => response.json())
        .catch((error) => {
                console.log(error)
            }
        )
}

export const getRounds = async () => {
    return fetch(`${apiUrl}/site/get-rounds`, {
        method: 'POST',
        headers: {}
    })
        .then(response => response.json())
        .catch((error) => {
                console.log(error)
            }
        )
}
export const updateGameResult = async (data) => {
    return fetch(`${apiUrl}/site/update-game-result`, {
        method: 'POST',
        body:JSON.stringify(data),
    })
        .then(response => response.json())
        .catch((error) => {
                console.log(error)
            }
        )
}


export const sendReview = async (data) => {
    return fetch(`${apiUrl}/site/send-review`, {
        method: 'POST',
        body:JSON.stringify(data),
    })
        .then(response => response.json())
        .catch((error) => {
                console.log(error)
            }
        )
}
